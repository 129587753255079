import React from "react";
import { useEffect, useState } from "react";
import AnimateCC from "react-adobe-animate";
import Zoom from 'react-reveal/Zoom';
import "./Home.scss";
import {
	Link,
} from "react-router-dom";

function Home() {
	return (
		<div className="container">
			<Home2 />
		</div>
	);
}
function Home2() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchData = () => {
			setLoading(false);
		};
		fetchData();
	}, []);
	return (
		<>
			<div className="mainvisual">
				<div className="mainvisual-catch">
					<h1 className="mainvisual-catch-copy">お買い物をするときのような<br /><span className="blue">w</span><span className="yellow">aku</span><span className="blue">w</span><span className="yellow">aku</span>を。</h1>
				</div>
				<div className="mainvisual-area">
					{loading ? (
						<p className="loader">ロード中。。。</p>
					) : (
						<AnimateCC animationName="mainvisual" composition="4DCF39456DFEC948A24ED3AA44A13FAF" />
					)}
				</div>
			</div>
			<div className="topservice">
				<h2 className="topservice-title">
					<img src={`${process.env.PUBLIC_URL}/images/index/title-service.svg`} alt="Our Service" />
				</h2>
				<div className="topservice-flex">
					<div className="topservice-flex-col">
						<p className="topservice-flex-col-text">
							webshopでは、web制作はもちろん、ブランディング、マーケティング、運用まで総合的にトータルサポートをしております。
							専門知識を備えたプロ集団だからこその視点で、お客様の企業や商品をたくさんの人に知ってもらうお手伝いをいたします。
						</p>
						<div className="topservice-right">
							<Link to="/service/" className="topservice-btn">
								<span>サービス詳細を見る</span>
								<img src={`${process.env.PUBLIC_URL}/images/index/btn-service.svg`} alt="サービス詳細を見る" />
							</Link>
						</div>
					</div>
					<div className="topservice-flex-col">
						<div className="topservice-support-col figure">
							<div className="blue">
								<div className="blue-left">集客</div>
								<div className="blue-right">リピート</div>
							</div>
							<div className="circle">
								<div className="circle-top">
									<Zoom delay={300} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">WEB制作<span className="circle-title-eng">Production</span></h4>
											</div>
											<div className="circle-col">
												<ul className="circle-list">
													<li className="icon-text">コーポレートサイト</li>
													<li className="icon-text">採用サイト</li>
													<li className="icon-text">ランディングページ</li>
													<li className="icon-text">サービス紹介サイト</li>
													<li className="icon-text">ECサイト</li>
													<li className="icon-text">マッチングサイト</li>
													<li className="icon-text">システム制作</li>
												</ul>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-left">
									<Zoom delay={500} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">マーケティング<span className="circle-title-eng">Marketing</span></h4>
											</div>
											<div className="circle-col">
												<div className="marketing-flex">
													<div className="marketing-col">
														<h5 className="title">検索エンジン</h5>
														<ul className="circle-list">
															<li className="icon-text">SEO・SEM</li>
															<li className="icon-text">リスティング</li>
														</ul>
														<h5 className="title">ポスティング広告</h5>
													</div>
													<div className="marketing-col">
														<h5 className="title">SNS 運用</h5>
														<ul className="circle-list">
															<li className="icon-text">facebook 投稿代行</li>
															<li className="icon-text">インスタグラム投稿代行</li>
															<li className="icon-text">YouTube動画制作</li>
															<li className="icon-text">広告運用</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-right">
									<Zoom delay={700} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">運用<span className="circle-title-eng">Operation</span></h4>
											</div>
											<div className="circle-col single">
												<ul className="circle-list">
													<li className="icon-text">LINE公式アカウント運用代行</li>
													<li className="icon-text">メルマガ/DM制作<br /> （制作～発送代行まで）</li>
													<li className="icon-text">顧客管理システム</li>
												</ul>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-bottom">
									<Zoom delay={700} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">ブランディング<span className="circle-title-eng">Branding</span></h4>
											</div>
											<div className="circle-col">
												<div className="marketing-flex">
													<div className="marketing-col">
														<ul className="circle-list">
															<li className="icon-text">ロゴ</li>
															<li className="icon-text">パンフレット</li>
															<li className="icon-text">チラシ</li>
															<li className="icon-text">冊子</li>
														</ul>
													</div>
													<div className="marketing-col">
														<ul className="circle-list">
															<li className="icon-text">名刺</li>
															<li className="icon-text">イラスト</li>
															<li className="icon-text">パッケージ</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Zoom>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
